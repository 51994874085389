/* eslint-disable no-nested-ternary */

// Node Modules
import { StaticImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

// Components
import YellowButton from '../../resuables/YellowButton';
import YellowLink from '../../resuables/YellowLink';

// Styles
import { above, colors, space } from '../../../style/design-system';

const CoursesPreview = ({ className }) => {
  const [currentlyDisplaying, setCurrentlyDisplaying] = useState('one');
  const [previouslyDisplayed, setPreviouslyDisplayed] = useState('one');
  const [hasBeenClicked, setHasBeenClicked] = useState(false);
  const [currentTimeout, setCurrentTimeout] = useState('');

  const handleClick = (e) => {
    clearTimeout(currentTimeout);
    setHasBeenClicked(true);
    const numberClicked = e.currentTarget.dataset.number;
    setPreviouslyDisplayed(currentlyDisplaying);
    setCurrentlyDisplaying(numberClicked);
  };

  const incrementState = useCallback((wasClicked) => {
    clearTimeout(currentTimeout);
    setHasBeenClicked(wasClicked);
    if (currentlyDisplaying === 'one') {
      setCurrentlyDisplaying('two');
    }
    else if (currentlyDisplaying === 'two') {
      setCurrentlyDisplaying('three');
    }
    else if (currentlyDisplaying === 'three') {
      setCurrentlyDisplaying('one');
    }
    setPreviouslyDisplayed(currentlyDisplaying);
  }, [currentlyDisplaying, currentTimeout]);

  const decrementState = () => {
    clearTimeout(currentTimeout);
    setHasBeenClicked(true);
    if (currentlyDisplaying === 'one') {
      setCurrentlyDisplaying('three');
    }
    else if (currentlyDisplaying === 'two') {
      setCurrentlyDisplaying('one');
    }
    else if (currentlyDisplaying === 'three') {
      setCurrentlyDisplaying('two');
    }
    setPreviouslyDisplayed(currentlyDisplaying);
  };

  useEffect(() => {
    if (!hasBeenClicked) setCurrentTimeout(setTimeout(() => incrementState(false), 5000));
    return null;
  }, [currentlyDisplaying]);

  return (
    <div className={`${className} position-relative`}>

      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-10 offset-lg-1">
            <div className="position-relative slides-wrapper">

              <div className={currentlyDisplaying === 'one' ? 'one position-absolute fade-in' : previouslyDisplayed === 'one' ? 'one position-absolute fade-out' : 'one position-absolute faded'}>
                <StaticImage src="../../../images/courses/course-virtual-fit.jpg" alt="Virtual Fit Course" />
                <div className="place-right col-10 offset-1 col-md-8 offset-md-4 col-lg-8 offset-lg-4 col-xl-8 offset-xl-4">
                  <div className="white-box">
                    <h3 className="mt-0">Virtual Fit Module &mdash;&nbsp;15&nbsp;mins.</h3>
                    <p className="my-0">Helps leaders build successful remote teams by outlining and identifying the ideal characteristics of remote&nbsp;employees.</p>
                  </div>
                  <div className="d-flex jc-fe">
                    <YellowLink to="/course/virtual-fit">
                      Learn More
                    </YellowLink>
                  </div>
                </div>
              </div>

              <div className={currentlyDisplaying === 'two' ? 'two position-absolute fade-in' : previouslyDisplayed === 'two' ? 'two position-absolute fade-out' : 'two position-absolute faded'}>
                <StaticImage className="image-flip" src="../../../images/courses/course-meaningful-connections.jpg" alt="Meaningful Connections Course" />
                <div className="place-left col-10 col-sm-10 col-md-8 col-lg-8 col-xl-8">
                  <div className="white-box">
                    <h3 className="mt-0">Meaningful Connections Module &mdash;&nbsp;15&nbsp;mins.</h3>
                    <p className="my-0">Offers a practical guide to forging a positive and productive remote work&nbsp;culture.</p>
                  </div>
                  <div className="d-flex jc-fs">
                    <YellowLink to="/course/assuming-meaningful-connections">
                      Learn More
                    </YellowLink>
                  </div>
                </div>
              </div>

              <div className={currentlyDisplaying === 'three' ? 'three position-absolute fade-in' : previouslyDisplayed === 'three' ? 'three position-absolute fade-out' : 'three position-absolute faded'}>
                <StaticImage src="../../../images/courses/course-assuming-remote-control.jpg" alt="Assuming Remote Control Course" />
                <div className="place-right col-10 offset-1 col-sm-10 col-md-8 offset-md-4 col-lg-9 offset-lg-3 col-xl-8 offset-xl-4">
                  <div className="white-box">
                    <h3 className="mt-0">Assuming Remote Control Module &mdash;&nbsp;12&nbsp;mins.</h3>
                    <p className="my-0">Provides managers with an applicable guide with tips on leading effective virtual&nbsp;meetings.</p>
                  </div>
                  <div className="d-flex jc-fe">
                    <YellowLink to="/course/assuming-remote-control">
                      Learn More
                    </YellowLink>
                  </div>
                </div>
              </div>
              <YellowButton className="d-block d-md-none position-absolute decrement" handleClickInParent={decrementState} padding={`${space.c} ${space.e}`}>&lt;</YellowButton>
              <YellowButton className="d-block d-md-none position-absolute increment" handleClickInParent={() => incrementState(true)} padding={`${space.c} ${space.e}`}>&gt;</YellowButton>
            </div>

          </div>
        </div>
      </div>

      <div className="bars position-absolute d-none d-md-block">
        <button className={currentlyDisplaying === 'one' ? 'bar cpb-bg-yellow' : 'bar bg-white'} data-number="one" onClick={handleClick} aria-label="View First Course" type="button" />
        <button className={currentlyDisplaying === 'two' ? 'bar cpb-bg-yellow' : 'bar bg-white'} data-number="two" onClick={handleClick} aria-label="View Third Course" type="button" />
        <button className={currentlyDisplaying === 'three' ? 'bar cpb-bg-yellow' : 'bar bg-white'} data-number="three" onClick={handleClick} aria-label="View Third Course" type="button" />
      </div>
    </div>
  );
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export default styled(CoursesPreview)`
  padding: ${space.g} 0;
  .place-left, .place-right {
    ${above.md`
      position: absolute;
    `}
  }
  .place-left {
    bottom: 2rem;
    ${above.md`
      left: -2rem;
      bottom: -5rem;
    `}
    ${above.lg`
      left: -5rem;
      bottom: 3rem;
    `}
    ${above.xl`
      left: -8rem;
      bottom: 7rem;
    `}
  }
  .place-right {
    bottom: 2rem;
    ${above.md`
      right: -2rem;
      bottom: -5rem;
    `}
    ${above.lg`
      right: -5rem;
      bottom: 3rem;
    `}
    ${above.xl`
      right: -8rem;
      bottom: 7rem;
    `}
  }
  .white-box {
    background-color: #fff;
    border: 1px solid ${colors.umaBlack};
    padding: ${space.f};
  }
  .slides-wrapper {
    height: 28rem;
    ${above.lg`
      height: 22rem;
    `}
    ${above.xl`
      height: 32rem;
    `}
  }
  .one, .two, .three {
    margin-bottom: ${space.l};
    height: 15rem;
    ${above.sm`
      margin-bottom: ${space.k};
      height: 18rem;
    `}
    ${above.md`
      height: 22rem;
    `}
    ${above.lg`
      height: 20rem;
      margin-bottom: ${space.h};
    `}
    ${above.xl`
      height: 28rem;
    `}
  }
  .fade-in {
    animation-name: ${fadeIn};
    animation-duration: 1.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  .fade-out {
    animation-name: ${fadeOut};
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  .faded {
    opacity: 0;
  }
  .gatsby-image-wrapper {
    height: 100%;
  }
  .two .gatsby-image-wrapper img {
    transform: rotateY(180deg);
  }
  .bars {
    left: calc(50% - 7rem);
    .bar {
      width: 4rem;
      height: .5rem;
      border: 1px solid ${colors.umaBlack};
      margin-left: 1rem;
      &:first-child {
        margin-left: 0;
      }
    }
    .bg-white {
      background-color: white;
    }
  }
  .decrement {
    left: 0;
    top: 25%;
  }
  .increment {
    right: 0;
    top: 25%;
  }
`;

CoursesPreview.propTypes = {
  className: PropTypes.string.isRequired,
};
