// Node Modules
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

// Styles
import { colors, space } from '../../style/design-system';

const YellowButton = ({
  children,
  className,
  handleClickInParent,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  padding,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  useGrayBackground,
}) => {
  const handleClick = () => handleClickInParent();

  return (
    <button className={className} onClick={handleClick} type="button">
      {children}
    </button>
  );
};

export default styled(YellowButton)`
  background-color: ${(props) => (props.useGrayBackground ? `${colors.umaDarkGray}` : `${colors.cpb.yellow}`)};
  border: 1px solid ${colors.umaBlack};
  color: ${(props) => (props.useGrayBackground ? '#fff' : `${colors.umaBlack}`)};
  padding: ${(props) => (props.padding ? `${props.padding}` : `${space.c} ${space.g}`)};
  &:hover {
    background-color: ${(props) => (props.useGrayBackground ? `${colors.umaBlack}` : `${colors.cpb.yellowDarkened}`)};
  }
`;

YellowButton.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  handleClickInParent: PropTypes.func.isRequired,
  padding: PropTypes.string,
  useGrayBackground: PropTypes.bool,
};

YellowButton.defaultProps = {
  padding: `${space.c} ${space.g}`,
  useGrayBackground: false,
};
