// Node Modules
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

// Components
import CoursesPreview from './CoursesPreview';

// Styles
import { space } from '../../../style/design-system';

const LearningTools = ({ className }) => (
  <div className={className}>
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
          <h2 className="text-center">No-Cost Learning Tools</h2>
          <p className="text-center">Let’s nurture a stronger, more knowledgeable workforce through education.</p>
        </div>
      </div>
    </div>
    <CoursesPreview />
  </div>
);

export default styled(LearningTools)`
  overflow: hidden;
  padding: ${space.h} 0;
`;

LearningTools.propTypes = {
  className: PropTypes.string.isRequired,
};
