/* eslint-disable max-len */
// Node Modules
import { StaticImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

// Components
import YellowButton from '../../resuables/YellowButton';

// Styles
import { above, colors, space } from '../../../style/design-system';

// Utils
import { scrollToJoinUsForm } from '../../../util/functions/scrollToJoinUsForm';

const PeopleClimbingSteps = ({ className }) => (
  <div className={className}>

    <div className="top">
      <div className="container">
        <div className="row z-high">
          <div className="col-12 coffset-lg-1 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2 z-high">
            <h3 className="h2">When we come together to solve problems, generations transcend.</h3>
            <p>Join our academic exploration into pivotal topics that will help us understand how together we can advance an important community challenge. Potential areas of focus that are currently under consideration include:</p>
            <ul className="greater-than-list">
              <li>The Impact of Higher Education Across Various&nbsp;Ethnicities</li>
              <li>Most Common Reasons for Student Attrition in a Post-COVID&nbsp;World</li>
            </ul>
            <p><strong>We invite you to build a community of care with us. We want to hear&nbsp;from you.</strong></p>
            <YellowButton handleClickInParent={scrollToJoinUsForm}>Let&#39;s Collaborate</YellowButton>
          </div>
        </div>
        <div className="graphic z-low">
          <StaticImage src="../../../images/home/steps.png" alt="An image of people helping one another climb a set of steps." />
        </div>
      </div>
    </div>

  </div>
);

export default styled(PeopleClimbingSteps)`
  color: #fff;
  background-color: ${colors.cpb.teal};
  padding: ${space.h} 0 0 0;
  overflow: hidden;
  .graphic {
    padding: 0 5vw;
    transform: translate(0, 1px);
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    ${above.tiny`
      margin-top: -2rem;
    `}
    ${above.sm`
      margin-top: -3rem;
      transform: translate(-2rem, 1px);
    `}
    ${above.md`
      transform: translate(0, 1px);
    `}
    ${above.xl`
      transform: translate(0, 2px);
    `}
  }
  .z-low {
    z-index: -1;
  }
  .z-high {
    z-index: 1;
  }
`;

PeopleClimbingSteps.propTypes = {
  className: PropTypes.string.isRequired,
};
