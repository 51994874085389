// Node Modules
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

// Components
import BlogLink from './BlogLink';

// Styles
import { space } from '../../style/design-system';

const BlogListing = ({ blogPosts, className }) => {
  const renderBlogPosts = () => blogPosts.map((post) => {
    const fm = post.node.frontmatter;
    return (<BlogLink key={fm.link} image={fm.image} link={fm.link} text={fm.text} />);
  });

  return (
    <div className={className}>
      <div className="container-md">
        <div className="row">
          {renderBlogPosts()}
        </div>
      </div>
    </div>
  );
};

export default styled(BlogListing)`
  padding: ${space.g} 0;
`;

BlogListing.propTypes = {
  // blogPosts: ,
  className: PropTypes.string.isRequired,
};
