/* eslint-disable max-len */
/* eslint-disable object-curly-newline */

// Node Modules
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { fontSize, colors, space } from '../../style/design-system';

const BlogLink = ({ className, image, link, text }) => (
  <div className={`${className} col-12 col-sm-6 col-lg-4`}>
    <a href={link} rel="noopener noreferrer" target="_blank">
      {image.path !== null && <GatsbyImage image={image.path.childImageSharp.gatsbyImageData} alt={text} /> }
      <p className="mt-4">{text}</p>
    </a>
  </div>
);

export default styled(BlogLink)`
  margin: ${space.c} 0;
  padding: 0 ${space.c};
  a {
    color: ${colors.umaBlack};
    height: 100%;
    display: block;
    padding: ${space.d};
    margin: auto;
    transition: all .3s ease-in-out;
    .not-gatsby-image {
      width: 100%;
    }
    p {
      font-size: ${fontSize.e};
    }
  }
`;

BlogLink.propTypes = {
  className: PropTypes.string.isRequired,
  // image: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
