// Node Modules
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

// Components
import ContentOverImage from '../../resuables/ContentOverImage';
import YellowButton from '../../resuables/YellowButton';

// Styles
import { above, colors, space } from '../../../style/design-system';

// Utils
import { scrollToJoinUsForm } from '../../../util/functions/scrollToJoinUsForm';

const HomeHero = ({ className }) => {
  const query = useStaticQuery(graphql`{
    heroImage: file(relativePath: {eq: "home/time-to-be-kind.jpg"}) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, layout: CONSTRAINED, width: 1920)
      }
    }
  }`);

  return (
    <div className={className}>
      <ContentOverImage
        alt="It's time to be kind."
        dripHeight="200px"
        dripColor="#fff"
        dripLeft="200px"
        imageIsContained
        includeDrip
        gatsbyImage={query.heroImage.childImageSharp.gatsbyImageData}
        renderContent={() => (
          <div className="content d-flex ai-c">
            <div className="container">
              <div className="row">
                <div className="col-10 offset-1 col-sm-10 col-md-8 offset-md-0 col-lg-6 col-xl-5 offset-xl-1">
                  <div className="white-box">
                    <p className="my-0">Building a community of care that fosters positive change, because when we care for others, care pays back. We seek to improve the lives of students by providing the resources, tools, and support they need to develop a successful healthcare education&nbsp;path.</p>
                  </div>
                  <div className="d-flex jc-fs">
                    <YellowButton useGrayBackground handleClickInParent={scrollToJoinUsForm}>
                      Join Our Care Community
                    </YellowButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )} />
    </div>
  );
};

export default styled(HomeHero)`
  .content {
    transform: translateY(-3rem);
    ${above.md`
      height: 18rem;
      transform: translateY(0);
    `}
    ${above.md`
      height: 28rem;
    `}
    ${above.lg`
      height: 28rem;
    `}
    ${above.xl`
      height: 28rem;
    `}
  }
  .white-box {
    background-color: #fff;
    border: 1px solid ${colors.umaBlack};
    padding: ${space.f};
  }
`;

HomeHero.propTypes = {
  className: PropTypes.string.isRequired,
};
