// Node Modules
import { Link } from 'gatsby';
import styled from 'styled-components';

// Styles
import { colors, space } from '../../style/design-system';

const YellowLink = styled(Link)`
  background-color: ${colors.cpb.yellow};
  border: 1px solid ${colors.umaBlack};
  color: ${colors.umaBlack};
  padding: ${space.c} ${space.g};
  text-decoration: none;
  &:hover {
    background-color: ${colors.cpb.yellowDarkened};
    color: ${colors.umaBlack};
    cursor: pointer;
    text-decoration: none;
  }
`;

export default YellowLink;
