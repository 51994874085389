// Node Modules
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

// Styles
import { above, space } from '../../../style/design-system';

const ContentBelowHero = ({ className }) => (
  <div className={className}>
    <div className="container">
      <div className="row">
        <div className="col-10 offset-1 col-md-10 offset-md-1 col-xl-8 offset-xl-2">
          <p className="m-0">With our passion rooted in education, Ultimate Medical Academy has seen this cycle of care firsthand through our 27+ years of helping healthcare students find their calling to&nbsp;care.</p>
        </div>
      </div>
    </div>
  </div>
);

export default styled(ContentBelowHero)`
  padding: 0 0 ${space.i} 0;
  ${above.md`
    padding-top: ${space.i};
    p {
      text-align: center;
    }
  `}
`;

ContentBelowHero.propTypes = {
  className: PropTypes.string.isRequired,
};
