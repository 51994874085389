// Node Modules
import * as React from 'react';

// Components
import ContentBelowHero from '../components/content-sections/home/ContentBelowHero';
import FindResources from '../components/content-sections/home/FindResources';
import JoinUsFormWrapper from '../components/form/presentational/JoinUsFormWrapper';
import HomeHero from '../components/content-sections/home/HomeHero';
import IndustryArticles from '../components/content-sections/home/IndustryArticles';
import Layout from '../components/core/layout';
import LearningTools from '../components/content-sections/home/LearningTools';
// import PartnersAll from '../components/content-sections/statics/PartnersAll';
import PeopleClimbingSteps from '../components/content-sections/home/PeopleClimbingSteps';
import RippleEffect from '../components/content-sections/home/RippleEffect';
import SEO from '../components/core/seo';

const IndexPage = () => (
  <Layout>
    <SEO
      title="Home"
      description="Building a community of care that fosters positive change, because when we care for others, care pays back." />
    <HomeHero />
    <ContentBelowHero />
    <FindResources />
    <RippleEffect />
    <IndustryArticles />
    <LearningTools />
    <PeopleClimbingSteps />
    {/* <PartnersAll /> */}
    <JoinUsFormWrapper hideCourses />
  </Layout>
);

export default IndexPage;
