// Node Modules
import React from 'react';

const ArticlesText = () => (
  <div>
    <div className="container">
      <div className="row">
        <div className="col-12 col-xl-8 col-lg-10 offset-lg-1 offset-xl-2">
          <div className="above-blog-links text-center">
            <h2 className="text-center">Industry Articles</h2>
            <p className="text-center">This content offers tips, articles, and information on a range of topics designed to improve your life from professional growth, to healthcare, to self-care and&nbsp;beyond.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ArticlesText;
