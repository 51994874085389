// Node Modules
import React from 'react';
import styled from 'styled-components';

// Style
import { above, colors, space } from '../../../style/design-system';

// Components
import ResourceForm from '../functional/ResourceForm';

type FormWrapperProps = {
  className?: string,
};

const FormWrapperDefaultProps = {
  className: undefined,
};

const FormWrapper = ({ className }: FormWrapperProps) => (
  <div className={className}>
    <div className="row">
      <div className="container">
        <div className="col-12 col-lg-10 offset-lg-1">
          <ResourceForm />
        </div>
      </div>
    </div>
  </div>
);

FormWrapper.defaultProps = FormWrapperDefaultProps;

export default styled(FormWrapper)`
  .sui-search-box {
    border: none;
    .sui-search-box__wrapper, .sui-search-box__submit {
      border: 1px solid ${colors.umaBlack};
    }
    .sui-search-box__wrapper {
      .sui-search-box__autocomplete-container, .sui-search-box__results-list {
        background: #fff;
        ul {
          list-style: none;
          padding-left: ${space.f};
          li {
            text-align: left;
            margin-bottom: ${space.b};
            text-indent: -2.125rem;
            margin-left: 1.125rem;
            &::before {
              content: ">";
              margin: 0.5rem 0.5rem 0px 1rem;
            }
          }
        }
      }
      .sui-search-box__text-input {
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
      }
    }
    .sui-search-box__submit {
      align-self: flex-start;
      height: 3.6rem;
      ${above.sm`
        transform: translateX(1px);
      `}
      ${above.md`
        height: 3.875rem;
        transform: translateX(0px);
      `}
    }
  }
`;
