// Node Modules
import React from 'react';
import styled from 'styled-components';

// Components
import ResourceFormWrapper from '../../form/presentational/ResourceFormWrapper';

// Assets
import MaroonHeartBackground from '../../../images/backgrounds/heart-pattern-tessellate-maroon.jpg';

// Styles
import { space } from '../../../style/design-system';

const FindResources = ({ className }: { className: string }) => (
  <div className={className}>
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
          <h2 className="white mt-0 mb-8">Find resources that may make life&nbsp;easier.</h2>
          <p className="white">When resources are within reach, communities&nbsp;thrive.</p>
          <p className="white mb-8">Driven by our commitment to care, we offer access to local resources to help elevate outcomes when and where they matter most — food, housing, health, transportation, and legal&nbsp;representation.</p>
          <ResourceFormWrapper />
          <p className="mt-4 mb-0">
            <small className="white">Search by zip code to find local&nbsp;resources.</small>
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default styled(FindResources)`
  background: url(${MaroonHeartBackground});
  background-repeat: repeat;
  padding: ${space.j} 0;
  text-align: center;
`;
