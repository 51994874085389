// Node Modules
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

// Components
import ArticlesText from '../statics/ArticlesText';
import BlogListing from '../../resuables/BlogListing';
import YellowLink from '../../resuables/YellowLink';

import { colors, space } from '../../../style/design-system';

const IndustryArticles = ({ className }) => {
  const queryForBlogs = useStaticQuery(graphql`{
    featuredBlogs: allMarkdownRemark(filter: {frontmatter: { tag: {eq: "featuredBlog"}}}) {
      edges {
        node {
        frontmatter {
            link
            text
            image {
              id
              path {
                childImageSharp {
                  gatsbyImageData(quality: 85, width: 400, placeholder: NONE, layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
  }`);

  // console.log({queryForBlogs});

  return (
    <div className={className}>
      <ArticlesText />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="blogs">
              <BlogListing blogPosts={queryForBlogs.featuredBlogs.edges} />
            </div>
            <div className="below-blog-links text-center mt-6">
              <YellowLink to="/articles/">More Articles</YellowLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default styled(IndustryArticles)`
  background-color: ${colors.umaLightGray};
  padding: ${space.h} 0;
`;

IndustryArticles.propTypes = {
  className: PropTypes.string.isRequired,
};
