/* eslint no-empty-pattern: off */

import * as React from 'react';
import { navigate } from 'gatsby';

import {
  AutocompleteResults, ResultFields, SearchBar, SearchProvider,
} from '../../search/resource';

const ResourceForm = () => (
  <SearchProvider>
    <SearchBar
      autocompleteResults={false}
      autocompleteView={(
        { autocompletedResults, value }: { autocompletedResults: ResultFields[], value: string },
      ) => (
        <AutocompleteResults autocompletedResults={autocompletedResults} value={value} />
      )}
      onSubmit={(searchTerm: string) => navigate(`/resources?q=${searchTerm}`)} />
  </SearchProvider>
);

export default ResourceForm;
