// Node Modules
import { graphql, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

// Components
import ContentOverImage from '../../resuables/ContentOverImage';
import YellowLink from '../../resuables/YellowLink';

// Styles
import { above, fontSize, space } from '../../../style/design-system';

const RippleEffect = ({ className }) => {
  const query = useStaticQuery(graphql`{
    rippleEffect: file(relativePath: {eq: "home/ripple-effect-bg.png"}) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, layout: CONSTRAINED, width: 1920)
      }
    }
  }`);

  return (
    <div className={className}>
      <ContentOverImage
        alt="A subtle ripple pattern"
        bgAlwaysAbsolute
        dripHeight="200px"
        dripColor="#802B59"
        dripLeft="200px"
        includeDrip
        gatsbyImage={query.rippleEffect.childImageSharp.gatsbyImageData}
        renderContent={() => (
          <div className="content d-flex ai-c">
            <div className="container">
              <div className="row">
                <div className="flex-wrapper col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div className="left">
                    <StaticImage src="../../../images/logos/rectangle-logo.png" alt="Ultiamte Medical Academy logo" />
                  </div>
                  <div className="right">
                    <h2 className="mt-md-0">The Ripple Effect of&nbsp;Care</h2>
                    <p className="mb-7">Caring for others creates a ripple effect of goodness.  With care, small ripples can become substantial waves that have the potential to transform the lives of others and impact their communities. One small act of kindness can inspire many, having a deep effect as they repeat exponentially.</p>
                    <YellowLink to="/why-we-care/">Why We Care</YellowLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )} />
    </div>
  );
};

export default styled(RippleEffect)`
  .content {
    height: 24rem;
  }
  .flex-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: ${space.f};
    padding-bottom: ${space.f};
    .right {
      text-align: center;
    }
    ${above.md`
      flex-direction: row;
      .left {
        flex: 1 1 50%;
        text-align: right;
        padding-right: ${space.g};
      }
      .right {
        flex: 1 1 50%;
        text-align: left;
        h2 {
          font-size: ${fontSize.i};
        }
      }
    `}
  }
  .bg-img-absolute {
    height: 24rem;
  }
`;

RippleEffect.propTypes = {
  className: PropTypes.string.isRequired,
};
