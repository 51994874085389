/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable @typescript-eslint/no-unused-vars */

// Node Module Imports
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Drip = ({ className, dripColor, dripHeight, dripLeft }) => (
  <svg
    className={className}
    viewBox="0 0 74 218"
    fill={dripColor}>
    <g stroke={dripColor} fill={dripColor} fillRule="evenodd">
      <g transform="translate(-351.000000, -670.000000)">
        <g transform="translate(350.999900, 669.999700)">
          <path d="M-1.42108547e-14,3.19744231e-14 L-1.42108547e-14,0.439 L3.529,0.439 C21.438,0.439 35.981,14.813 36.275,32.651 L36.303,32.651 C36.598,14.813 51.141,0.439 69.05,0.439 L73.674,0.439 L73.674,3.19744231e-14 L-1.42108547e-14,3.19744231e-14 Z" fill={dripColor} />
          {/* The line below is for the line that extends from the drip. It is not included on the site now, but might return in the future. */}
          {/* <line x1="36.5001" y1="17.5003" x2="36.0001" y2="217.0003" stroke={dripColor} fill={dripColor} strokeLinecap="square"></line> */}
        </g>
      </g>
    </g>
  </svg>
);

Drip.propTypes = {
  className: PropTypes.string.isRequired,
  dripColor: PropTypes.string,
  dripHeight: PropTypes.string,
  dripLeft: PropTypes.string,
};

Drip.defaultProps = {
  dripColor: '#fff',
  dripHeight: '200px',
  dripLeft: '200px',
};

export default styled(Drip)`
  position: absolute;
  height: ${(props) => props.dripHeight};
  left: ${(props) => props.dripLeft};
  top: -1px;
`;
