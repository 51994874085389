// Node Modules
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

// Components
import Drip from './Drip';

// Styles
import { above, colors } from '../../style/design-system';

const ContentOverImage = ({
  alt,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  bgAlwaysAbsolute,
  className,
  dripColor,
  dripHeight,
  dripLeft,
  gatsbyImage,
  imageIsContained,
  includeDrip,
  renderContent,
}) => (
  <div className={`${className} position-relative`}>
    {!imageIsContained && (
      <div className="bg-img-absolute">
        <GatsbyImage alt={alt} className="gatsby-image" image={gatsbyImage} objectFit="cover" objectPosition="center top" />
      </div>
    )}

    {imageIsContained && (
      <div className="container">
        <div className="row solid-bg-row">
          <div className="position-relative col-12 col-xl-10 offset-xl-1 contained-image-wrapper">
            <div className="bg-img-absolute-contained">
              <GatsbyImage alt={alt} className="gatsby-image" image={gatsbyImage} objectFit="cover" objectPosition="center top" />
            </div>
            {renderContent()}
          </div>
        </div>
      </div>
    )}
    {includeDrip && <Drip dripColor={dripColor} dripHeight={dripHeight} dripLeft={dripLeft} />}
    {!imageIsContained && renderContent()}
  </div>
);

export default styled(ContentOverImage)`
  ${above.xxl`
    background-color: ${(props) => (props.imageIsContained ? `${colors.cpb.yellow}` : 'transparent')};
  `}
  overflow: hidden;
  .bg-img-absolute {
    position: ${(props) => (props.bgAlwaysAbsolute ? 'absolute' : 'initial')};
    height: 100%;
    ${above.md`
      position: absolute;
    `}
    .gatsby-image {
      inset: 0px;
      width: 100vw;
      height: 16rem;
      z-index: -1;
      ${above.md`
        height: 100%;
      `}
    }
  }
  .bg-img-absolute-contained {
    position: ${(props) => (props.bgAlwaysAbsolute ? 'absolute' : 'initial')};
    z-index: 0;
    ${above.md`
      position: absolute;
      height: 100%;
    `}
    .gatsby-image {
      inset: 0px;
      width: 100%;
      height: 16rem;
      z-index: -1;
      ${above.md`
        height: 100%;
      `}
    }
  }
  .contained-image-wrapper {
    padding: 0;
    ${above.md`
      padding: 0 20px;
    `}
  }
  .solid-bg-row {
    ${above.md`
      background-color: ${(props) => (props.imageIsContained ? `${colors.cpb.yellow}` : 'transparent')};
    `}
  }
`;

ContentOverImage.propTypes = {
  alt: PropTypes.string,
  bgAlwaysAbsolute: PropTypes.bool,
  className: PropTypes.string.isRequired,
  dripColor: PropTypes.string.isRequired,
  dripHeight: PropTypes.string.isRequired,
  dripLeft: PropTypes.string.isRequired,
  gatsbyImage: PropTypes.object.isRequired,
  imageIsContained: PropTypes.bool,
  includeDrip: PropTypes.bool,
  renderContent: PropTypes.func.isRequired,
};

ContentOverImage.defaultProps = {
  alt: '',
  bgAlwaysAbsolute: false,
  imageIsContained: false,
  includeDrip: false,
};
